.ScenesTextBoxScroller {
  /*height: 1200px; */
}

.ScenesTextInner {
  height: 100%;
}

.ScenesTextContent {
  background: white;

  position: relative;
  top: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -o-box-sizing: border-box;
}
