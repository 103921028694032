.Demo__some_network {
  vertical-align: top;
  /*  display: inline-block; */
  margin-right: 5px;
  text-align: center;
}

.Demo__some_network__share_button {
  cursor: pointer;
}

.Demo__some_network__share_button:hover:not(:active) {
  opacity: 0.75;
}

.Demo__some-network__custom-icon {
  width: 32px;
  height: 32px;
}
